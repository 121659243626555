import axios from "axios"
import { SessionDataHandling } from "./SessionDataHandling"

const { REACT_APP_API_BASE_URL } = process.env

const getCommonHeadersForBftServices = () => {
  return {
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
  }
}

export const installCommonHeadersForBffServicesInterceptor = () => {
  axios.interceptors.request.use(config => {
    const needsAuthHeader = config.url?.indexOf(REACT_APP_API_BASE_URL!) === 0

    if (!needsAuthHeader) {
      return config;
    }

    const sessionData = SessionDataHandling.getSessionDataFromStorage();
    const authorizationHeader = {
      transactionId: sessionData.transactionId || '',
      token: sessionData.token || '',
    };

    return {
      ...config,
      headers: {
        ...config.headers,
        common: {
          ...config.headers.common,
          ...getCommonHeadersForBftServices(),
          Authorization: JSON.stringify(authorizationHeader),
        },
      },
    }
  });
}
